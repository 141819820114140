import { getDeviceSize } from '~/utils/device'
import hotBreadcrumb from '~/components/Molecules/Breadcrumb/Breadcrumb.vue'
import hotLanguageSwitch from '~/components/Molecules/LanguageSwitch/LanguageSwitch.vue'
import hotMadeWithLove from '~/components/Molecules/MadeWithLove/MadeWithLove.vue'

export default {
  props: {
    navigation: {
      type: Array,
      required: true,
    },

    copyright: {
      type: String,
      required: true,
    },

    terms: {
      type: Object,
    },

    ethics: {
      type: Object,
    },

    settings: {
      type: Object,
    },

    corporate: {
      type: String,
    },

    propBus: {
      type: Object,
    },

    helpCenter: {
      type: Object,
    },
  },

  components: {
    hotBreadcrumb,
    hotLanguageSwitch,
    hotMadeWithLove,
  },

  data() {
    return {
      year: null,
      showCookieNotice: true,
    }
  },

  computed: {
    hasCorporate() {
      return this.corporate ? true : false
    },
    hasHelpCenter() {
      return this.helpCenter ? true : false
    },
  },

  mounted() {
    this.createYear()

    this.$nextTick(() => {
      window.addEventListener('mousemove', this.loadHandTalkScript, { once: true })
    })
  },

  methods: {
    createYear() {
      this.year = new Date().getFullYear()
    },

    showCookieNoticeModal() {
      if (window.hmReshowCookieNotice) {
        window.hmReshowCookieNotice()
      }

      return
    },

    loadHandTalkScript() {
      const locale = this.$i18n.locale
      const query = this.$route.query.xp
      let isDesktop = null

      const size = getDeviceSize()

      if (/desktop(?:\-(?:xl|[lm]))?/.test(size)) {
        isDesktop = true
      } else {
        isDesktop = false
      }

      if (locale === 'pt-br' && this.isHomepage && isDesktop) {
        const handtalkScript = document.createElement('script')
        handtalkScript.src = 'https://plugin.handtalk.me/web/latest/handtalk.min.js'
        handtalkScript.id = 'handtalkId'
        handtalkScript.defer = true

        handtalkScript.onload = () => {
          var ht = new HT({ token: '6c87e51d3de96e073573a3c3929c4f2f', avatar: 'MAYA' })
        }

        document.body.appendChild(handtalkScript)
        console.log('loaded hand talk')
        window.removeEventListener('mousemove', this.loadHandTalkScript)
      }
    },
  },
}
